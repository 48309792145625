//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* istanbul ignore file */
export default {
  name: 'DataDisplay',
  props: {
    value: {
      type: [String, Object],
      required: true
    }
  },
  computed: {
    isDateTime () {
      return typeof this.value === 'string' && this.value.includes(':')
    }
  },
  methods: {
    getRef () {
      return this.$refs.datePreview
    }
  }
}
