//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableHeader from './TableHeader'
import TableRow from './TableRow'

export default {
  name: 'Table',
  components: { TableHeader, TableRow },
  props: {
    /**
     * v-model interface, expects a array with ids for selected items of the table ["1", "5"]
     */
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * The list of columns to display. 
     * type: [ { name: 'name of column' }, ... ]
     */
    columns: {
      type: Array,
      required: true
    },
    /**
     * The current column that is being used for sorting
     */
    sortColumnName: {
      type: String,
      required: false,
      default: () => null
    },
    /**
     * Sorting the current column but in reversed order
     */
    isSortOrderReversed: {
      type: Boolean,
      required: false,
      default: () => false
    },

    /**
     * An array of: DataApiResponseItem
     * See: https://github.com/molgenis/molgenis-frontend/blob/master/packages/data-explorer/src/types/ApiResponse.ts for type definition
     */
    entities: {
      type: Array,
      required: true
    },
    /**
     * Please provide the ColumnName of the ID colomn
     */
    idAttribute: {
      type: String,
      required: true
    },
    /**
     * Name of the current table
     */
    tableName: {
      type: String,
      required: true
    },
    /**
     * De we wish to be able to select rows?
     */
    isSelectable: {
      type: Boolean,
      default: () => false
    }
  },
  methods:{
    getEntityId (entity) {
      return entity[this.idAttribute].toString()
    },
    sortEvent (name) {
      /**
       * Emits the name of to column the user wishes to sort
       * @property {String} sort - name of column to sort
       * @event sort
       */
      this.$emit('sort', name)
    },
    toggleSelectedItemsHandler (id) {
      /**
        * Emits the id of to column of the user selects a new colunm
        * @property {String} id - id of column selected
        * @event toggleSelectedItemsHandler
        */
      if(this.value.includes(id)){
        this.$emit('input', this.value.filter(item => item !== id))
      }else{
        this.$emit('input', [...this.value,id])
      }
      this.$emit('toggleSelectedItemsHandler', id)
    }
  }
}
