//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SatisfyAllCheckbox",
  props: {
    /**
     * This is the satisfyAll v-model value. 
     * @model
     */
    
    value: {
      type: Boolean,
      default: () => false
    },
    /**
     * The label to show on the left of the satisfy all Checkbox
     */
    satisfyAllLabel: {
      type: String,
      required: false,
      default: () => 'Satisfy all'
    }
  },
  computed: {
    satisfyAllModel: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
