//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RefDisplay',
  props: {
    value: {
      // {id {string}, label: {string}}
      type: [Object, Array],
      required: true
    },
    metadata: {
      type: Object,
      required: true
    }
  },
  computed: {
    displayValue () {
      return Array.isArray(this.value) ? this.value.map(v => v.label).join(', ') : this.value.label
    },
    hasLinkValue () {
      return Array.isArray(this.value) ? !!this.value.length : !!this.value
    }
  },
  methods: {
    handleRefClicked () {
      this.$eventBus.$emit('show-reference-table', {
        refEntityType: this.metadata.refEntityType,
        value: this.value
      })
    }
  }
}
