//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ChangeFilters',
  props: {
    /**
     * The filters to select from
     * @model
     */
    filters: {
      type: Array,
      required: true
    },
    /**
     * The selected filters
     * @model
     */
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selected: this.value
    }
  },
  watch: {
    value: {
      handler (newValue) {
        this.selected = [...newValue]
      },
      immediate: true
    }
  },
  methods: {
    unique: (value, index, items) => items.indexOf(value) === index,
    checkboxHandler (option, state) {
      if (state) {
        this.selected.push(option.name)
      } else {
        this.selected = this.selected.filter(item => item !== option.name)
      }

      // parent compound toggle
      if (option.type === 'compound-title') {
        const subCompounds = this.filters
          .filter(item => item.compound === option.name)
          .map(item => item.name)
        if (this.selected.includes(option.name)) {
          // Select all and make unique
          this.selected = [...this.selected, ...subCompounds].filter(
            this.unique
          )
        } else {
          // Deselect all
          this.selected = this.selected.filter(
            item => !subCompounds.includes(item)
          )
        }
      }

      // child compound toggle
      if (option.compound) {
        const subCompounds = this.filters
          .filter(item => item.compound === option.compound)
          .map(item => item.name)
        const allSelected = subCompounds.every(item =>
          this.selected.includes(item)
        )
        const noneSelected = subCompounds.every(
          item => !this.selected.includes(item)
        )
        const partialSelected = !allSelected && !noneSelected
        if (allSelected) {
          this.selected = [option.compound, ...this.selected].filter(
            this.unique
          )
        }
        if (noneSelected) {
          this.selected = this.selected.filter(
            item => item !== option.compound
          )
        }
        if (partialSelected) {
          this.selected = this.selected.filter(
            item => item !== option.compound
          )
        }
      }
      this.$emit('input', this.selected)
    }
  }
}
