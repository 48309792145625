//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NumberFilter',
  props: {
    /**
     * The minimum allowed filter value.
     */
    min: {
      type: Number,
      default: () => Number.MIN_SAFE_INTEGER
    },
    /**
     * The maximum allowed filter value.
     */
    max: {
      type: Number,
      default: () => Number.MAX_SAFE_INTEGER
    },
    /**
     * Step size when using browser UI controls.
     */
    step: {
      type: Number,
      default: () => 1
    },
    /**
     * The number to be used in filtering items.
     * @model
     */
    value: {
      type: Number,
      default: () => 0
    },
    /**
     * Whether to use an additional UI slider to set the input value.
     */
    useSlider: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      /**
       * v-model return value
       *
       * @event input
       * @property {Array} Returns new array without the deleted item
       */
      set (value) {
        if (typeof value === 'string' && value === '') {
          this.$emit('input', undefined)
        } else {
          value = Math.min(Math.max(value, this.min), this.max)
          /**
          * v-model return value
          *
          * @event input
          * @property {Float} Returns new filter value or NaN
          */
          this.$emit('input', parseFloat(value))
        }
      }
    }
  }
}
