//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* istanbul ignore file */
export default {
  name: 'StringDisplay',
  props: {
    value: {
      type: [String, Number, Boolean],
      required: true
    },
    rowIndex: {
      type: Number,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    }
  },
  methods: {
    getRef () {
      return this.$refs.stringPreview
    }
  },
  computend: {
    valueAsString () {
      return this.value !== undefined || this.value !== null ? this.value.toString() : ''
    }
  }
}
