//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FilterCard',
  props: {
    /**
     * Extra class to style b-card-header
     */
    headerClass: {
      type: String,
      default: () => ''
    },
    /**
     * Unique filter identifier
     */
    name: {
      type: String,
      required: true
    },
    /**
     * Human readable label descibing the filter
     */
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    /**
     * Indicates if filter is shown in collapsed state or not
     */
    collapsed: {
      type: Boolean,
      required: false,
      default: () => true
    },
    /**
     * Indicates if the user can collapse the filter
     */
    collapsable: {
      type: Boolean,
      required: false,
      default: () => true
    },
    /**
     * Human readable text with aditional description of the filter
     */
    description: {
      type: String,
      required: false,
      default: () => ''
    },
    /**
     * Whether or not the user can remove the filter or not
     */
    canRemove: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      isOpen: this.collapsable ? !this.collapsed : true
    }
  },
  computed: {
    cssClasses: function () {
      const classes = this.headerClass.split(' ').filter(i => i)
      if (this.collapsable) {
        classes.push('collapsable')
      }
      return classes.join(' ')
    },
    iconStyle () {
      return {
        transform: `rotate(${this.isOpen ? 90 : 0}deg)`,
        transition: 'transform 0.2s'
      }
    }
  },
  methods: {
    removeFilter () {
      this.$emit('removeFilter', this.name)
    },
    toggleState () {
      if (this.collapsable) {
        this.isOpen = !this.isOpen
        return this.isOpen
      }
      return false
    }
  }
}
