//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* istanbul ignore file */
export default {
  name: 'HTMLDisplay',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  methods: {
    getRef () {
      return this.$refs.htmlPreview
    }
  }
}
