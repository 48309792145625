//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Table from './Table'

export default {
  name: 'ApiTable',
  components: { Table },
  props: {
    /**
     * v-model interface, expects a array with ids for selected items of the table ["1", "5"]
     */
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * molgenis v3 data api request
     */
    data:{
      type: Object,
      required: true 
    },
    /**
     * molgenis v3 metadata api request
     */
    metadata:{
      type: Object,
      required: true 
    },
    /**
     * The current column that is being used for sorting
     */
    sortColumnName: {
      type: String,
      required: false,
      default: () => null
    },
    /**
     * Sorting the current column but in reversed order
     */
    isSortOrderReversed: {
      type: Boolean,
      required: false,
      default: () => false
    },
    /**
     * De we wish to be able to select rows?
     */
    isSelectable: {
      type: Boolean,
      default: () => false
    }
  },
  computed:{
    getColumns (){
      if(this.metadata && this.metadata.data && this.metadata.data.attributes){
        return this.metadata.data.attributes.items.map(item => item.data)
      } else {
        return []
      }
    },
    getTableName (){
      if(this.metadata && this.metadata.data){
        return this.metadata.data.id
      } else {
        return ""
      }
    },
    getIdAttribute (){
      if(this.metadata && this.metadata.data && this.metadata.data.attributes){
        return this.metadata.data.attributes.items.find(item => item.data.idAttribute === true).data.name
      } else {
        return "id"
      }
    },
    getEntities (){
      if(this.data && this.data.items){
        return this.data.items.map(item => item.data)
      } else {
        return []
      }
    }
  },
  methods:{
    sortEvent (name) {
      /**
       * Emits the name of to column the user wishes to sort
       * @property {String} sort - name of column to sort
       * @event sort
       */
      this.$emit('sort', name)
    },
    toggleSelectedItemsHandler (id) {
      /**
        * Emits the id of to column of the user selects a new colunm
        * @property {String} id - id of column selected
        * @event toggleSelectedItemsHandler
        */
      this.$emit('toggleSelectedItemsHandler', id)
    },
    inputEvent (selection) {
      /**
        * Emits changed selection
        * @property {String} id - id of column selected
        * @event input
        */
      this.$emit('input', selection)
    }
  }
}
