//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataDisplayCell from './dataDisplayTypes/DataDisplayCell'

export default {
  name: 'TableRow',
  components: { DataDisplayCell },
  props: {
    /**
     * Add the molgenis id, used for row selections and mutations
     * Note: check the metadata to make sure you have the correct id attribute 
     */
    id: {
      type: String,
      required: true
    },
    /**
     * Name of the current table
     */
    tableName: {
      type: String,
      required: true
    },
    /**
     * Row of table data of type: DataApiResponseItem
     * See: https://github.com/molgenis/molgenis-frontend/blob/master/packages/data-explorer/src/types/ApiResponse.ts for type definition
     */
    rowData: {
      type: Object,
      required: true
    },
    /**
     * Information about all visable columns of type:
     * [ { id, name, type, refEntityType, expression } ]
     */
    visibleColumns: {
      type: Array,
      required: true
    },
    /**
     * is this current row selected?
     */
    isSelected: {
      type: Boolean,
      required: false,
      default: () => false
    },
    /**
     * De we have edit rights on this table?
     */
    isEditable: {
      type: Boolean,
      default: () => false
    },
    /**
     * will show shopping cart button template if true. Will also hide edit/delete row opperations 
     */
    showSelected: {
      type: Boolean,
      required: true
    },
    /**
     * index of the current row ( used to make a unique id in StringFilter.vue )
     */
    rowIndex: {
      type: Number,
      required: true
    }
  },
  methods: {
    getColumnValue (name) {
      if (name in this.rowData && this.rowData[name] !== undefined) {
        return this.rowData[name]
      }
    },
    toggleSelectedItemsHandler (id) {
      /**
        * Emits the id of to column of the user selects a new colunm
        * @property {String} id - id of column selected
        * @event toggleSelectedItemsHandler
        */
      this.$emit('toggleSelectedItemsHandler', id)
    }
  }
}
