//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DateTimeFilter',
  props: {
    /**
     * Define the start of your calendar range.
     * @values ISOString, null
     */
    min: {
      default: () => null,
      type: [String, null]
    },
    /**
     * Define the end of your calendar range.
     * @values ISOString, null
     */
    max: {
      default: () => null,
      type: [String, null]
    },
    /**
     * Defines a maximal Date/Datetime thats selectable in the calendar widget.
     * @values right, left, top, bottom
     */
    opens: {
      default: () => 'right',
      type: String
    },
    /**
     * Toggles time selection in the calendar widget.
     * @values true, false
     */
    time: {
      type: Boolean,
      default: () => true
    },
    /**
     * Toggles single date selection; uses only min property in that case.
     * @values true, false
     */
    range: {
      type: Boolean,
      default: () => true
    },
    /**
     * The default selected date.
     * @model
     */
    value: {
      type: Array,
      default: () => [null, null]
    },
    /**
     * Append the dialog to the body tag, useful if filter is used within a container that uses overflow:scroll
     * @model
     */
    appendToBody: {
      type: Boolean,
      default: () => false
    }
  },
  data: function () {
    return {
      dateRange: {
        startDate: null,
        endDate: null
      }
    }
  },
  computed: {
    formattedDate: function () {
      const date = this.dateRange
      if (!date.startDate || !date.endDate) {
        return 'Select...'
      }
      if (date.startDate.toISOString() === date.endDate.toISOString()) {
        return this.formatDate(date.startDate)
      } else {
        return `${this.formatDate(date.startDate)} - ${this.formatDate(date.endDate)}`
      }
    },
    pickerFormat () {
      return this.time ? 'yyyy-mm-dd HH:MM:ss' : 'yyyy-mm-dd'
    }
  },
  watch: {
    value (newValue) {
      this.setDateRange(newValue)
    }
  },
  beforeMount () {
    this.setDateRange(this.value)
  },
  methods: {
    clearValue: function () {
      this.dateRange = {
        startDate: null,
        endDate: null
      }

      this.$emit('input', undefined)
    },
    setDateRange (value) {
      if (value) {
        this.dateRange.startDate = this.createDateFromValue(value[0])
        this.dateRange.endDate = this.createDateFromValue(value[1])
      }
    },
    updateValues: function () {
      this.$emit('input', [this.dateRange.startDate, this.dateRange.endDate])
    },
    createDateFromValue (value) {
      if (value) {
        return isNaN(value) ? new Date(Date.parse(value)) : new Date(value)
      }
    },
    formatDate (dateTime) {
      return this.time ? dateTime.toLocaleString() : dateTime.toLocaleDateString()
    }
  }
}
