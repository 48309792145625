//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FileDisplay',
  props: {
    value: {
      /*
      *  id:string
      *  filename:string
      *  contentType:string
      *  label:string
      *  size:number
      *  url:string (full url)
      */
      type: Object,
      required: true
    }
  }
}
