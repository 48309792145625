//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FilterCard from './FilterCard.vue'

import ActiveFilters from '../ActiveFilters.vue'
import ChangeFilters from '../ChangeFilters.vue'
import CheckboxFilter from '../CheckboxFilter.vue'
import DateTimeFilter from '../DateTimeFilter.vue'
import MultiFilter from '../MultiFilter.vue'
import NumberFilter from '../NumberFilter.vue'
import RangeFilter from '../RangeFilter.vue'
import StringFilter from '../StringFilter.vue'

export default {
  name: 'FilterContainer',
  components: {
    ActiveFilters,
    ChangeFilters,
    CheckboxFilter,
    DateTimeFilter,
    MultiFilter,
    NumberFilter,
    RangeFilter,
    StringFilter,
    FilterCard
  },
  props: {
    /**
     * An array of filters to render.
     */
    filters: {
      type: Array,
      required: true
    },
    /**
     * Object Key/values of filter names/values from all concerned filters.
     * @model
     */
    value: {
      type: Object,
      default: () => ({})
    },
    /**
     * The filters to show; an array of filter name properties.
     */
    filtersShown: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Lock or unluck filter addition/removal.
     */
    canEdit: {
      type: Boolean,
      required: false,
      default: () => false
    },
    /**
    * Set active filters selection dialogue style, choose between 'dropdown' or 'modal'.
    * Defaults to using 'dropdown'
    */
    dialogStyle: {
      type: String,
      required: false,
      default: () => 'dropdown'
    },
    /**
    * Change action label
    */
    filterActionLabel: {
      type: String,
      required: false,
      default: () => 'Add filter'
    },
    /**
    * Filter list label
    */
    filterListLabel: {
      type: String,
      required: false,
      default: () => 'Filters'
    }
  },
  data () {
    return {
      filtersToShow: this.filtersShown,
      filterToAdd: null,
      drag: false,
      width: 0,
      mobileToggle: false
    }
  },
  computed: {
    doCollapse () {
      // Bootstrap's mobile collapse width
      return this.width <= 576
    },
    doDragDrop () {
      return this.canEdit && !this.doCollapse
    },
    listOfVisibleFilters () {
      return this.filtersToShow.map(id => this.filters.find(filter => filter.name === id))
        .filter(item => item !== undefined)
        .filter(item => item.type !== 'compound-title')
    }
  },
  watch: {
    filtersShown (newValue) {
      this.filtersToShow = newValue
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.width = window.innerWidth
    },
    removeFilter (name) {
      this.filtersToShow = this.filtersToShow.filter(filter => name !== filter)
      /**
       * Emit the filters that are supposed to be visible.
       * @property {Array} filtersToShow - An array of filter name properties
       * @event update
       */
      this.$emit('update', this.filtersToShow)

      const selections = { ...this.value }
      delete selections[name]
      this.$emit('input', selections)
    },
    selectionChange (name, value) {
      /**
       * Update the model with the updated values from related filters.
       * @property {Object} - Filter name/value
       * @event input
       */

      // clear an empty checkbox and range filter
      if (Array.isArray(value)) {
        if (value.length === 0) {
          value = undefined
        } else if (value.every(item => item === null)) {
          value = undefined
        }
      }

      const newSelection = { ...this.value, [name]: value }
      // remove all undefined properties
      Object.keys(newSelection).forEach(key => newSelection[key] === undefined && delete newSelection[key])
      this.$emit('input', newSelection)
    },
    selectionUpdate () {
      this.$emit('update', this.filtersToShow)
    }
  }
}
