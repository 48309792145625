//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CartSelectionToast',
  props: {
    /**
     * Passthrough setting from BootstrapVue
     */
    toastClass: {
      type: [String, Object, Array]
    },
    /**
     * Passthrough setting from BootstrapVue
     */
    headerClass: {
      type: [String, Object, Array]
    },
    /**
     * Passthrough setting from BootstrapVue
     */
    bodyClass: {
      type: [String, Object, Array]
    },
    /**
    /**
     * Default descriptive text.
     * Use this to specify the amount of selected items in the cart
     * For example:
     * "3 items selected"
     */
    cartSelectionText: {
      type: String,
      required: true
    },
    /**
     * See: https://bootstrap-vue.org/docs/components/toast#b-toaster-target-component
     */
    location: {
      type: String,
      required: false,
      default: () => 'b-toaster-bottom-right'
    },
    /**
     * See: https://bootstrap-vue.org/docs/reference/color-variants#color-variants-and-css-class-mapping
     */
    backgroundVariant: {
      type: String,
      required: false,
      default: () => 'info'
    },
    /**
     * See: https://bootstrap-vue.org/docs/components/button#contextual-variants
     */
    buttonVariant: {
      type: String,
      required: false,
      default: () => 'secondary'
    },
    /**
     * If this array is set (via v-model), then in will allow you to preview your selection
     * Expects a array of strings or a Array of objects
     * When using a array of objects provide the 'labelAttribute' to select the field to display
     */
    value: {
      type: Array,
      required: false,
      default: () => []
    },
    /**
     * Label attribute to display for use with object based store data.
     */
    labelAttribute: {
      type: String,
      required: false,
      default: () => 'name'
    },
    /**
     * Button click handler
     */
    clickHandler: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    previewToggle: false
  }),
  computed: {
    selectionText () {
      return this.previewToggle ? 'Hide' : 'Show'
    }
  },
  methods: {
    previewLabel (item) {
      if (typeof item === 'string') {
        return item
      } else if (typeof item === 'object') {
        if (typeof item[this.labelAttribute] === 'string') {
          return item[this.labelAttribute]
        }
      }
      return ''
    },
    removeItem (itemName) {
      /**
       * v-model return value
       *
       * @event input
       * @property {Array} Returns new array without the deleted item
       */
      this.$emit('input', this.value.filter(item => item !== itemName))
    }
  }
}
