//
//
//
//
//
//
//
//

/* istanbul ignore file */
export default {
  name: 'EmailDisplay',
  props: {
    value: {
      type: String,
      required: true
    }
  }
}
