//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RangeFilter',
  props: {
    /**
     * The minimum allowed filter value.
     */
    min: {
      type: Number,
      default: () => Number.MIN_SAFE_INTEGER
    },
    /**
     * The maximum allowed filter value.
     */
    max: {
      type: Number,
      default: () => Number.MAX_SAFE_INTEGER
    },
    /**
     * Step size when using browser UI controls.
     */
    step: {
      type: Number,
      default: () => 1
    },
    /**
     * The number to be used in filtering items.
     * @model
     */
    value: {
      type: Array,
      default: () => [null, null]
    },
    /**
     * Whether to use an additional UI slider to set the input value.
     */
    useSlider: {
      type: Boolean,
      default: () => false
    }
  },
  data: function () {
    return {
      rangeValue: this.value
    }
  },
  watch: {
    value (newValue) {
      if (newValue[0] == null && newValue[1] == null) {
        this.rangeValue = [null, null]
      }
    }
  },
  methods: {
    setRangeValue (id, value) {
      this.rangeValue[id] = value
      this.handleRangeValueChange()
    },
    clampValue (value, max, min) {
      return Math.min(Math.max(value, min), max)
    },
    handleRangeValueChange () {
      if (this.rangeValue[0] === '') {
        this.rangeValue[0] = null
      }
      if (this.rangeValue[1] === '') {
        this.rangeValue[1] = null
      }

      if (this.rangeValue[0] != null) {
        this.rangeValue[0] = this.clampValue(this.rangeValue[0], this.max, this.min)
      }
      if (this.rangeValue[1] != null) {
        this.rangeValue[1] = this.clampValue(this.rangeValue[1], this.max, this.min)
      }
      this.rangeValue = [this.rangeValue[0], this.rangeValue[1]]

      /**
      * Clone to break reactive loop.
      * @property {Array} rangeValue Range filter value
      */
      this.$emit('input', [...this.rangeValue])
    }
  }
}
