//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableHeader from '../TableHeader'
import TableRow from '../TableRow'

export default {
  name: 'RefTable',
  components: { TableHeader, TableRow },
  props: {
    /**
     * This will show a loading indicater until set to true
     */
    isDataLoaded: {
      type: Boolean,
      required: true
    },
    /**
     * The rows of the ref table to show
     */
    entitiesToShow: {
      type: Array
    },
    /**
     * The metadata of the ref table to show
     */
    metaData: {
      type: Object
    }
  },
  computed: {
    visibleColumns () {
      return this.metaData.attributes
        .filter(a => a.visible)
        .map(a => ({ id: a.id, name: a.name, type: a.type, refEntityType: a.refEntityType }))
    }
  }
}
